import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyCQbgV3NS4bokTJswoo4BE5q8gbkmht5VE",
    authDomain: "avenuapp.co",
    databaseURL: "https://avenu-2bd9c.firebaseio.com",
    projectId: "avenu-2bd9c",
    storageBucket: "avenu-2bd9c.appspot.com",
    messagingSenderId: "198041750826",
    appId: "1:198041750826:web:1d13863bdd1726c7b0e253",
    measurementId: "G-N8P5T4D0XN",
    uid: "test",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const auth = getAuth(app);
export default db;
