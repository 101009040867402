import React, { useEffect, useContext } from "react";

import { AuthContext } from "../../auth/AuthContext";
import { UiContext } from "../../../uiContext";
import { EventContext } from "../../events/EventContext";
import RegistrationView from "../views/RegistrationView";

import { useNavigate } from "react-router-dom";
const RegistrationScreen = (props) => {
    const navigate = useNavigate();
    const { user, userFound, userAuth, createUser, onLoginCallbackObj, readUserIfPresent, allUsers } =
        useContext(AuthContext);

    const { url, setUrl } = useContext(UiContext);

    const { event, updateGoingUsers, buyFreeTicket } = useContext(EventContext);

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        if (userFound === true) {
            console.log("DEBUG user fired");
            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] === buyFreeTicket
            ) {
                onLoginCallbackObj["func"](userAuth.user.uid, event);
                navigate(url);
                setUrl(null);
            } else if (onLoginCallbackObj &&
                onLoginCallbackObj["updateGoingUsers"] === updateGoingUsers
            ) {
                onLoginCallbackObj["updateGoingUsers"](userAuth.user.uid, event);
                onLoginCallbackObj["addEventCodeToUser"](userAuth.user.uid, event);
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] === "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"],
                    user
                );
                navigate(navPath);
            } else if (
                (onLoginCallbackObj &&
                    onLoginCallbackObj["name"] === "navToTickets") ||
                onLoginCallbackObj["name"] === "navToCreate" ||
                onLoginCallbackObj["name"] === "navToHost"
            ) {
                let navPath = onLoginCallbackObj["func"]();
                navigate(navPath);
            } else {
                navigate(-3);
            }
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                "userFound:", userFound,
                "user:", user,
                "userAuth:", userAuth
            );
        }
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <RegistrationView
            createProfile={(userData) => {
                console.log("DEBUG user auth", userAuth);
                createUser(userAuth.user.uid, userData);
            }}
            userAuth={userAuth}
            allUsers={allUsers}
        />
    );

};

export default RegistrationScreen;
