import React, { useState, useEffect, useContext } from "react";

import AuthRegisterView from "../views/AuthRegisterView";
import { AuthContext } from "../AuthContext";
import { UiContext } from "../../../uiContext";

import { useNavigate } from "react-router-dom";
import { findAllByTestId } from "@testing-library/react";
import { EventContext } from "../../events/EventContext";

const AuthRegisterScreen = (props) => {
    const navigate = useNavigate();
    const {
        confirm,
        user,
        userFound,
        userAuth,
        setUserAuth,
        loginEmail,
        signUpEmail,
        readUserIfPresent,
        onLoginCallbackObj,
        setOnLoginCallbackObj,
        error,
    } = useContext(AuthContext);
    const { url, setUrl } = useContext(UiContext);
    const { event, buyFreeTicket } = useContext(EventContext);

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        if (userFound === true) {
            console.log("DEBUG userfound..?", user, "userAUth:", userAuth);
            
            if (!onLoginCallbackObj) {
                navigate(-3);
                return;
            }

            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] == buyFreeTicket
            ) {
                onLoginCallbackObj["func"](userAuth.user.uid, event);
                console.log("DEBUG fired onLoginCallbackObj");
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"]
                );
                console.log("DEBUG navpath fired", navPath);
                navigate(navPath);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToTickets"
            ) {
                let navPath = onLoginCallbackObj["func"];
                console.log("navToTickets fired", navPath);
                navigate(navPath());
            } else {
                navigate(-3);
            }
        } else if (userFound === false) {
            navigate("/auth/signup");
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                userFound,
                user
            );
        }

        console.log("DEBUG user fired for some reason", user);
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthRegisterView
                bubbleTitle="Enter"
                bubbleAction={(password) => signUpEmail(password)}
                loginError={error}
            />
        </>
    );
};

export default AuthRegisterScreen;
