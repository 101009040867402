import React, { useState, useEffect } from "react";
import db, { auth } from "../../firebaseConfig";

import {
    getAuth,
    signInWithPhoneNumber,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
    fetchSignInMethodsForEmail,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import {
    doc,
    setDoc,
    getDoc,
    updateDoc,
    getDocs,
    documentId,
    query,
    collection,
    where,
    arrayUnion,
    FieldValue,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const AuthContext = React.createContext();

function AuthProvider(props) {
    const [confirm, setConfirm] = useState(null);
    const [userAuth, setUserAuth] = useState(null);
    const [user, setUser] = useState(null);
    const [userFound, setUserFound] = useState(null);
    const [change, setChange] = useState(0);
    const [onLoginCallbackObj, setOnLoginCallbackObj] = useState(null);
    const [friends, setFriends] = useState([]);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: (response) => {},
            },
            auth
        );
    };

    const login = (confirm, code) => {
        setChange(change + 1);
        confirm
            .confirm(code)
            .then((result) => {
                setUserAuth(result);
                console.log("DEBUG userAuth set", result);
            })
            .catch((error) => {
                setError(error);
                console.log("ERROR in login: ", error);
            });
    };

    const code = (phone) => {
        generateRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phone, appVerifier)
            .then((confirmationResult) => {
                console.log("DEBUG confirm", confirmationResult);

                window.confirmationResult = confirmationResult;
                setConfirm(confirmationResult);
            })
            .catch((error) => {
                console.log("ERROR in code: ", error);
            });
    };

    const navigateSignIn = (email, navigate) => {
        console.log("email", email);
        setEmail(email);
        fetchSignInMethodsForEmail(auth, email)
            .then((methods) => {
                console.log("methods", methods);
                if (methods.length > 0) {
                    navigate("/auth/password");
                } else {
                    navigate("/auth/register");
                }
            })
            .catch((error) => {
                console.log("ERROR in email fetch", error);
                navigate("/auth/register");
            });
    };

    const loginEmail = (password) => {
        setChange(change + 1);
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                setUserAuth(result);
                console.log("DEBUG userAuth set", result);
            })
            .catch((error) => {
                setError(error);
                console.log("ERROR in login: ", error);
            });
    };

    const signUpEmail = (password) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((result) => {
                setUserAuth(result);
                console.log("DEBUG userAuth set", result);
            })
            .catch((error) => {
                setError(error);
                console.log("ERROR in login: ", error);
            });
    };

    const readUserIfPresent = async (userId) => {
        setUserFound(null);
        const docSnap = await getDoc(doc(db, "users", userId));
        if (docSnap.exists()) {
            const userData = docSnap.data();
            userData.id = docSnap.id;
            setUser(userData);
            setUserFound(true);
            console.log("DEBUG found userData", userData);
        } else {
            setUserFound(false);
            console.log("DEBUG not found userData");
        }
    };

    const createUser = async (userId, userData) => {
        const userMedia = [];

        const storage = getStorage();
        let mediaUrl = null;

        if (userData.userMedia.length > 0) {
            await new Promise((resolve, reject) => {
                userData.userMedia.forEach(async (media, index) => {
                    const storageRef = ref(
                        storage,
                        `users/000${userId}-${index}.jpg`
                    );

                    await uploadBytes(storageRef, media).then((snapshot) => {
                        console.log("Uploaded a blob or file!");
                        getDownloadURL(snapshot.ref).then((downloadURL) => {
                            mediaUrl = downloadURL;

                            console.log("DEBUG mediaUrl", mediaUrl);
                            if (mediaUrl === null) {
                                throw new Error();
                            }

                            userMedia.push(mediaUrl);

                            if (userMedia.length === userData.userMedia.length)
                                resolve();
                        });
                    });
                });
            });
        }

        const newUser = {
            ...userData,
            userMedia,
            createdAt: new Date(),
        };

        console.log("DEBUG user id:", userId, "userData", userData);
        const tempUser = {
            ...userData,
            createdAt: new Date(),
        };
        const testID = "000" + userId;

        const docRef = doc(db, "users", userId);
        setDoc(docRef, newUser)
            .then(() => {
                readUserIfPresent(userId);
            })
            .catch((error) => {
                console.log(
                    `createUser = (${userId}, ${userData}) => ${error}`
                );
            });
    };

    const fetchAllUsers = async () => {
        try {
            const userCollection = collection(db, "users");
            const userSnapshot = await getDocs(userCollection);

            // Transform into an object for faster lookups
            const userMap = userSnapshot.docs.reduce((acc, doc) => {
                acc[doc.id] = { id: doc.id, ...doc.data() };
                return acc;
            }, {});

            setAllUsers(userMap);
            // console.log("DEBUG All Users Map: ", userMap);
        } catch (error) {
            console.error("ERROR fetching users: ", error);
        }
    };

    useEffect(() => {
        if (userAuth && userFound) {
            console.log("DEBUG saving user");
            localStorage.setItem("userAuth", JSON.stringify(userAuth));
        }
    }, [userAuth, userFound]);

    useEffect(() => {
        const localUserAuth = JSON.parse(localStorage.getItem("userAuth"));

        if (localUserAuth) {
            console.log("DEBUG loading user");
            setUserAuth(localUserAuth);
            setUserFound(true);
        }
    }, []);

    useEffect(() => {
        if (user) {
            async function fetchFriends(friends) {
                const userBatches = [];
                for (let i = 0; i < friends.length / 10; i++) {
                    userBatches.push(friends.slice(i * 10, (i + 1) * 10));
                }

                const batchRefs = userBatches.map(async (batch) => {
                    return await getDocs(
                        query(
                            collection(db, "users"),
                            where(documentId(), "in", batch)
                        )
                    );
                });

                const friendsData = [];

                await Promise.all(batchRefs).then((querySnapshots) => {
                    querySnapshots.forEach((querySnapshot) => {
                        querySnapshot.forEach((documentSnapshot) => {
                            const friend = documentSnapshot.data();
                            friend.id = documentSnapshot.id;
                            friendsData.push(friend);
                        });
                    });
                });

                setFriends(friendsData);
            }
            fetchFriends(user.userFriends);
        }
    }, [user]);

    useEffect(() => {
        fetchAllUsers();
    }, []); // This will fetch all users once when the component mounts

    return (
        <AuthContext.Provider
            value={{
                login: login,
                code: code,
                confirm: confirm,
                user: user,
                userFound: userFound,
                userAuth: userAuth,
                createUser: createUser,
                change: change,
                setChange: setChange,
                readUserIfPresent: readUserIfPresent,
                navigateSignIn: navigateSignIn,
                loginEmail: loginEmail,
                signUpEmail: signUpEmail,
                onLoginCallbackObj: onLoginCallbackObj,
                setOnLoginCallbackObj: setOnLoginCallbackObj,
                error: error,
                friends,
                allUsers,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
