import React, { useState, useEffect, useContext } from "react";

import AuthCodeView from "../views/AuthCodeView";
import { AuthContext } from "../AuthContext";
import { UiContext } from "../../../uiContext";

import { useNavigate } from "react-router-dom";
import { findAllByTestId } from "@testing-library/react";
import { VenueContext } from "../../venues/VenueContext";
import { EventContext } from "../../events/EventContext";

const AuthCodeScreen = (props) => {
    const navigate = useNavigate();
    const {
        confirm,
        user,
        userFound,
        userAuth,
        setUserAuth,
        login,
        readUserIfPresent,
        onLoginCallbackObj,
        setOnLoginCallbackObj,
        error,
    } = useContext(AuthContext);
    const { url, setUrl } = useContext(UiContext);
    const { event, buyFreeTicket, updateGoingUsers } = useContext(EventContext);
    const { venue, updateVenueGoingList } = useContext(VenueContext);

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        if (userFound === true && user) {
            if (!onLoginCallbackObj) {
                navigate(-3);
                return;
            }

            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] == buyFreeTicket
            ) {
                onLoginCallbackObj["func"](userAuth.user.uid, event);
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["updateGoingUsers"] == updateGoingUsers
            ) {
                onLoginCallbackObj["updateGoingUsers"](
                    userAuth.user.uid,
                    event
                );
                onLoginCallbackObj["addEventCodeToUser"](
                    userAuth.user.uid,
                    event
                );
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"],
                    user
                );
                navigate(navPath);
            } else if (
                (onLoginCallbackObj &&
                    onLoginCallbackObj["name"] == "navToTickets") ||
                onLoginCallbackObj["name"] == "navToCreate" ||
                onLoginCallbackObj["name"] == "navToHost"
            ) {
                let navPath = onLoginCallbackObj["func"];
                navigate(navPath());
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToVenueDetail"
            ) {
                updateVenueGoingList(userAuth.user.uid, venue);
                let navPath = onLoginCallbackObj["func"];
                navigate(navPath());
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToEdit"
            ) {
                let navPath = onLoginCallbackObj.func;
                navigate(navPath().route, { state: navPath().params });
            } else {
                navigate(-3);
            }
        } else if (userFound === false) {
            navigate("/auth/signup");
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                userFound,
                user
            );
        }

        console.log("DEBUG user fired for some reason", user);
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthCodeView
                bubbleTitle="Login"
                bubbleAction={(code) => login(confirm, code)}
                loginError={error}
            />
        </>
    );
};

export default AuthCodeScreen;
