import { StyleSheet } from "react-native";

import { darkThemeBackground, sky } from "../../../../utils/color";

import { color, snow } from "../../../../utils/color";
import style from "../../../../styles/viewStyles";

export default StyleSheet.create({
    buttonBubble: {
        marginTop: 24,
        marginBottom: 24,

        backgroundColor: sky,
    },

    buttonStyle: {
        position: "fixed",
        bottom: 10,
        width: "100%",
    },

    buttonTitle: {
        color: "black",
    },

    text: {
        width: "90%",
        alignSelf: "center",
        textAlign: "center",
        color: snow,
    },

    container: {
        justifyContent: "flex-start",
        alignItems: "center",

        paddingTop: 64,
        paddingBottom: 16,
    },

    modal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        //backgroundColor: "rgba(0, 0, 0, 0.5)",
    },

    screen: {
        flex: 1,
        backgroundColor: darkThemeBackground,
        height: "100%",
        minHeight: "100vh",
    },

    sheet: {
        paddingTop: 16,
    },

    textfield: {
        margin: 0,
        backgroundColor: snow,
    },

    wrapper: {
        flex: 1,
        width: "100%",
        backgroundColor: darkThemeBackground,
        height: "100%",
    },
    component: {
        width: "100%",
        paddingLeft: 24,
        paddingRight: 24,
    },

    scrollviewDropdown: {
        backgroundColor: "#2A2A2A",
        height: 400,
        paddingTop: 12,
        paddingBottom: 12,
        margin: 24,

        borderRadius: 8,
    },
});
