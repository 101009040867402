import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { SafeAreaView, View, Text, ScrollView, FlatList } from "react-native";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import LabeledBarTextField from "../../../../components/fields/LabeledBarTextField";
import LabeledPickerField from "../../../../components/fields/LabeledPickerField";
import ProfileEditImage from "../../../../components/custom/ProfileEditImage";

import { GENDERS } from "../../../../utils/constants";

import style from "./style";
import { snow, white, color, sky } from "../../../../utils/color";
import ItemRow from "../../../../components/rows/ItemRow"

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isCompleted = (fields) => {
    return (
        fields.userFirst !== "" &&
        fields.userLast !== "" &&
        // fields.userGender !== "" &&
        fields.userMedia.length !== 0 &&
        fields.userBirthdate &&
        !isNaN(fields.userBirthdate)
    );
};

const RegistrationView = (props) => {
    const [firstName, onChangeFirstName] = useState("");
    const [lastName, onChangeLastName] = useState("");
    const [birthdateString, setBirthdateString] = useState("");
    const [birthdate, setBirthDate] = useState(
        props.userAuth.user.email ? new Date(2023, 0, 1) : null
    );
    const [media, setMedia] = useState([]);
    const [school, setSchool] = useState("");

    const [searchInvitedBy, setSearchInvitedBy] = useState("");

    const [showBirthdatePicker, setShowBirthdatePicker] = useState(false);
    const [showGenderPicker, setShowGenderPicker] = useState(false);

    const [showInvitedByDropdown, setShowInvitedByDropdown] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState("");
    const [displaySearch, setDisplaySearch] = useState("");
    const [invitedByUserId, setInvitedByUserId] = useState("");
    const [showNoResultsText, setShowNoResultsText] = useState(false);
    const scrollViewRef = useRef(null);

    const invitedByName = (
        userFirst,
        userLast,
    ) => {
        return `${userFirst} ${userLast}`;
    };


    const renderSearchItem = (item, theme) => (
        <ItemRow
            title={invitedByName(
                item.userFirst,
                item.userLast,
                item.id,
            )}
            image={item.userMedia[0]}
            icons={[]}
            theme={theme}
            onPress={() => {
                setTimeout(() => {
                    setInvitedByUserId(item.id);
                    setDisplaySearch(`${item.userFirst} ${item.userLast}`);
                    setShowInvitedByDropdown(false);
                }, 210);
                // Keyboard.dismiss();
            }}
        />
    );

    const keyExtractor = (item) => item.id;


    // This effect triggers scrolling when the dropdown becomes visible
    useEffect(() => {
        if (showInvitedByDropdown && scrollViewRef.current) {
            window.scrollTo({
                top: scrollViewRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [showInvitedByDropdown]);


    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchInvitedBy.length > 2) {
                const search = searchInvitedBy.replace(/\s+/g, ' ').trim().toLowerCase();
                const usersArray = Object.values(props.allUsers || []);
                if (usersArray.length > 0) {
                    const filterUsers = usersArray.filter((user) => {
                        const fullName = `${user.userFirst} ${user.userLast}`.replace(/\s+/g, ' ').trim().toLowerCase();
                        return fullName.includes(search);
                    })
                    setFilteredUsers(filterUsers);
                    setShowInvitedByDropdown(true);
                    setShowNoResultsText(filterUsers.length === 0);
                }
            }
            else {
                setInvitedByUserId("");
                setFilteredUsers([]);
                setShowNoResultsText(false);
                setShowInvitedByDropdown(false);
            }
        }, 1000);
        return () => clearTimeout(handler);
    }, [searchInvitedBy])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const profile = {
        userFirst: firstName,
        userLast: lastName,
        userSearch: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
        userHandle: "",
        userPhone: props.userAuth.user.phoneNumber,
        userEmail: props.userAuth.user.email ?? "",
        userBio: "",
        userBirthdate: birthdate,
        userGender: props.userAuth.user.email ? "Prefer not to say" : "",
        userSchool: school,
        userMedia: media,
        userInvitedBy: invitedByUserId,
        userStatus: { statusType: "NOT SURE", updatedAt: new Date() },
        userFriends: [],
        userMeets: [],
        userRequests: [],
        userRequestsMeet: [],
        userBlocks: [],
        userTokens: [],
        userPrivacy: true,
        userWork: "",
        userInstagram: "",
    };

    const firstNameCharLimit = 30;
    const lastNameCharLimit = 30;
    const invitedByCharLimit = 30;
    const workEducationCharLimit = 20;
    const schoolCharLimit = 50;

    const handleChangeBirthdateString = (birthdateString) => {
        const formattedText = formatBirthdate(birthdateString);
        setBirthdateString(formattedText);
        if (birthdateString.length !== 10 && birthdateString.length !== 8) {
            setBirthDate(null);
        } else {
            const date = new Date(birthdateString);
            setBirthDate(date);
        }
    };

    const formatBirthdate = (input) => {
        // format the birthday input automatically
        let formattedText = input.replace(/[^0-9]/g, ""); // remove non-numeric characters

        if (formattedText.length > 2 && formattedText.length <= 4) {
            // Add slash after the month
            formattedText =
                formattedText.slice(0, 2) + "/" + formattedText.slice(2);
        } else if (formattedText.length > 4) {
            // Add slashes after the month and day
            formattedText =
                formattedText.slice(0, 2) +
                "/" +
                formattedText.slice(2, 4) +
                "/" +
                formattedText.slice(4, 8);
        }

        return formattedText;
    };

    const handleEnter = () => {
        if (isCompleted(profile)) {
            props.createProfile(profile);
        }
    };

    return (
        <SafeAreaView style={style.screen}>
            <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={() => { scrollViewRef.current?.scrollToEnd({ animated: true }) }}
                style={{ flex: 1, padding: 10, overflowY: 'auto', maxHeight: '1500px' }}
                contentContainerStyle={{ paddingBottom: 5 }}
                keyboardShouldPersistTaps="handled"
            >

                <View style={style.wrapper}>
                    <ProfileEditImage image={media[0]} onPress={setMedia} />
                    {props.userAuth.user.phoneNumber ? (
                        <Text style={style.text}>
                            Show off your fabulous face for secure, hassle-free
                            event entry! 😁
                        </Text>
                    ) : null}
                    {media.length !== 0 ? (
                        <View style={style.wrapper}>
                            <LabeledBarTextField
                                theme={props.theme}
                                label={
                                    props.userAuth.user.phoneNumber
                                        ? "First Name"
                                        : "Organization Name"
                                }
                                text={firstName}
                                required={true}
                                placeholder={
                                    props.userAuth.user.phoneNumber
                                        ? "Enter Your First Name"
                                        : "Enter Your Organization Name"
                                }
                                charLimit={firstNameCharLimit}
                                rightLabel={`${firstName.length}/${firstNameCharLimit}`}
                                onChange={onChangeFirstName}
                                onSubmit={handleEnter}
                                onFocused={() => {
                                    setShowBirthdatePicker(false);
                                    setShowGenderPicker(false);
                                }}
                                style={{
                                    ...style.textfield,
                                    backgroundColor: "#2A2A2A",
                                }}
                            />
                            <LabeledBarTextField
                                theme={props.theme}
                                label={
                                    props.userAuth.user.phoneNumber
                                        ? "Last Name"
                                        : "City or Campus"
                                }
                                text={lastName}
                                required={true}
                                placeholder={
                                    props.userAuth.user.phoneNumber
                                        ? "Enter Your Last Name"
                                        : "Enter Your City or Campus"
                                }
                                charLimit={lastNameCharLimit}
                                rightLabel={`${lastName.length}/${lastNameCharLimit}`}
                                onChange={onChangeLastName}
                                onSubmit={handleEnter}
                                onFocused={() => {
                                    setShowBirthdatePicker(false);
                                    setShowGenderPicker(false);
                                }}
                                style={{
                                    ...style.textfield,
                                    backgroundColor: "#2A2A2A",
                                }}
                            />
                            {props.userAuth.user.phoneNumber ? (
                                <>
                                    <LabeledBarTextField
                                        theme={props.theme}
                                        label="Birthdate"
                                        text={birthdateString}
                                        placeholder="MM/DD/YYYY"
                                        keyboardType="numeric"
                                        required={true}
                                        onChange={handleChangeBirthdateString}
                                        onSubmit={handleEnter}
                                        onFocused={() => {
                                            setShowBirthdatePicker(false);
                                            setShowGenderPicker(false);
                                        }}
                                        style={{
                                            ...style.textfield,
                                            backgroundColor: "#2A2A2A",
                                        }}
                                    />
                                    <LabeledBarTextField
                                        theme={props.theme}
                                        label="School"
                                        text={school}
                                        required={false}
                                        placeholder="Enter Your School"
                                        charLimit={schoolCharLimit}
                                        leftLabel=" (optional)"
                                        rightLabel={`${school.length}/${schoolCharLimit}`}
                                        onChange={setSchool}
                                        onSubmit={handleEnter}
                                        onFocused={() => {
                                            setShowBirthdatePicker(false);
                                            setShowGenderPicker(false);
                                        }}
                                        style={{
                                            ...style.textfield,
                                            backgroundColor: "#2A2A2A",
                                        }}
                                    />
                                    <LabeledBarTextField
                                        theme={props.theme}
                                        label={"Invited By"}
                                        text={displaySearch}
                                        required={false}
                                        placeholder={"First & Last Name"}
                                        charLimit={invitedByCharLimit}
                                        leftLabel=" (optional)"
                                        rightLabel={`${displaySearch.length}/${invitedByCharLimit}`}
                                        onChange={(text) => {
                                            setSearchInvitedBy(text);
                                            setDisplaySearch(text);
                                        }}
                                        onSubmit={handleEnter}
                                        onFocused={() => {
                                            setShowBirthdatePicker(false);
                                            setShowGenderPicker(false);
                                        }}
                                        style={{
                                            ...style.textfield,
                                            backgroundColor: "#2A2A2A",
                                        }}
                                    />
                                    {showInvitedByDropdown && (
                                        <ScrollView
                                            style={[
                                                style.scrollviewDropdown,
                                            ]}
                                        >
                                            {searchInvitedBy.length > 2 && (
                                                showNoResultsText ? (
                                                    <View style={{ ...style.text, marginTop: 15 }}>
                                                        <Text style={style.text}> No results found </Text>
                                                    </View>
                                                ) : (
                                                    <FlatList
                                                        data={filteredUsers}
                                                        renderItem={({ item }) => renderSearchItem(item, props.theme)}
                                                        keyExtractor={keyExtractor}
                                                        keyboardShouldPersistTaps="always"
                                                    />
                                                ))}
                                        </ScrollView>
                                    )}
                                </>
                            ) : null}
                            <View style={{ marginTop: 120 }} />
                        </View>
                    ) : null}
                    <BubbleButton
                        title="Confirm"
                        onPress={() => props.createProfile(profile)}
                        buttonStyle={style.buttonStyle}
                        bubbleStyle={style.buttonBubble}
                        titleStyle={style.buttonTitle}
                        disabled={!isCompleted(profile)}
                        color={sky}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

RegistrationView.propTypes = {
    // userPhone: PropTypes.string.isRequired,
    createProfile: PropTypes.func.isRequired,
};

export default RegistrationView;
